import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ListKunjunganPerGroup from "./listKunjunganByGroup";
import { useDispatch, useSelector } from "react-redux";
import { Dna, Watch } from "react-loader-spinner";
import { Label } from "reactstrap";
import ReactPaginate from "react-paginate";
import { getGroupKunjunganByCalonNasabah } from "stores";

function GroupCalonNasabah(data) {
  const dispatch = useDispatch();
  const korcab = useSelector((state) => state.korcabReducer);
  const [listGroup, setListGroup] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [idGroupNasabah, setIdGroupNasabah] = React.useState("");

  //   const [searchText, setSearchText] = React.useState("");

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleClick = () => {
    dispatch({ type: "SET_DETAIL_NASABAH", data: true });
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_KUNJUNGAN_BY_GROUP", data: false });
    setIdGroupNasabah(val.idGroupKunjungan);
  };

  //   const handleFilterChange = () => {
  //     setIsLoading(true);
  //     setPage(0);
  //     getAllCalonNasabah(dispatch, {
  //       page: page + 1,
  //       skip: totalPerHalaman,
  //       cari: searchText,
  //     })
  //       .then((response) => {
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: "Terjadi kesalahan saat memuat data.",
  //         });
  //       });
  //   };

  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter") {
  //       handleFilterChange();
  //     }
  //   };

  //   const handleSearch = (e) => {
  //     setSearchText(e.target.value);
  //   };

  //   const handleClearSearch = () => {
  //     setSearchText("");
  //   };

  //   const handleDetailsClick = (val) => {
  //     dispatch({ type: "SET_DETAIL_NASABAH", data: false });
  //     // dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });
  //   };

  React.useEffect(() => {
    let tmp =
      korcab.groupKujunganById.docs &&
      korcab.groupKujunganById.docs.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Group Kunjungan</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val);
                    handleDetailsClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </>
          ),
        };
      });
    setListGroup(tmp);
  }, [korcab.groupKujunganById]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Progress":
        return {
          fontWeight: "bold",
          backgroundColor: "#FDE5DD",
          textColor: "#F85317",
        };
      case "Done":
        return {
          fontWeight: "bold",
          backgroundColor: "#BAD9FD",
          textColor: "#1776F1",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colGroupKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) => index + korcab.groupKujunganById.pagingCounter,
    },
    {
      name: "ID Group Kunjungan",
      minWidth: "290px",
      center: true,
      selector: (row) => row.idGroupKunjungan,
    },
    {
      name: "ID Pinjaman",
      minWidth: "290px",
      center: true,
      selector: (row) => (row.loan === "" ? "-" : row.loan),
    },
    {
      name: "Nopeg Marketing",
      minWidth: "290px",
      center: true,
      selector: (row) => row.marketing.nopeg,
    },
    {
      name: "Marketing",
      minWidth: "290px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Status Kunjungan",
      minWidth: "290px",
      center: true,
      selector: (row) => row.statusKunjungan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusKunjungan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusKunjungan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getGroupKunjunganByCalonNasabah(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      idCalonNasabah: data.data,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);
  return (
    <>
      {korcab.modalKunjunganByGroup ? (
        <Container fluid>
          <Button
            className="btn-transparent mr-2"
            type="button"
            variant="primary"
            size="md"
            style={{
              border: "none",
              backgroundColor: "#F75D59",
              marginBottom: 20,
              border: 0,
            }}
            onClick={handleClick}
          >
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Card>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "#2C2B2B",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                detail nasabah
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <Card style={{ border: 0 }}> */}
              <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                <div className="row">
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      nama lengkap
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.namaNasabah}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      NIK
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.nik}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      Alamat
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.alamat}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      no handphone
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.noHp}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      tanggal lahir
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {moment(data.value.tglLahir).format("DD MMMMM YYYY")}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      kategori Nasabah
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.kategoriNasabah === "1"
                          ? "PNS Biasa"
                          : data.value.kategoriNasabah === "2"
                          ? "PNS Prapurna Bakti"
                          : data.value.kategoriNasabah === "3"
                          ? "Pensiunan"
                          : data.value.kategoriNasabah === "4"
                          ? "PRA THT"
                          : "KPS (Kredit Pensiunan)"}
                      </Label>
                    </li>
                  </div>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      tipe kunjungan
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.typeKunjungan}
                      </Label>
                    </li>
                  </div>
                  {/* <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      Marketing
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.value.marketing.nama}
                      </Label>
                    </li>
                  </div> */}
                </div>
              </div>
              {/* </Card> */}
            </Card.Body>
          </Card>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "#2C2B2B",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    list kunjungan nasabah
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {isLoading ? (
                    <div
                      className="loader-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="30"
                        color="#F75D59"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div>
                      {listGroup.length > 0 ? (
                        <DataTable
                          columns={colGroupKunjungan}
                          data={listGroup}
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          //   progressPending={isLoading}
                        />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginBottom: "1rem",
                            }}
                          >
                            Maaf, belum ada kunjungan nasabah saat ini.
                          </p>
                          <img
                            src={require("assets/img/sorry.jpg").default}
                            alt="Sorry"
                            style={{
                              maxWidth: "20%",
                              display: "block",
                              margin: "0 auto",
                            }}
                          />
                        </div>
                      )}
                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col md={4}>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label style={{ fontWeight: "bold" }}>
                                  Rows per page
                                </label>
                              </div>
                              <Col md="4" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={options.find(
                                    (option) => option.value === totalPerHalaman
                                  )}
                                  onChange={handlePageSizeChange}
                                  options={options}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <ReactPaginate
                              forcePage={page}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-itemss-containers"
                              previousLinkClassName="pages-links-labelss"
                              nextClassName="pages-itemss-containers"
                              nextLinkClassName="pages-links-labelss"
                              breakClassName="pages-itemss-containers"
                              pageCount={korcab.groupKujunganById.totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labelss"
                              pageClassName="pages-itemss-containers"
                              activeClassName="activees"
                              activeLinkClassName="aactivess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={page}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPage(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <ListKunjunganPerGroup data={idGroupNasabah} />
        </>
      )}
    </>
  );
}

export default GroupCalonNasabah;
